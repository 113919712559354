<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs5>
              <v-text-field
                v-model="dreg"
                label="Enter msisdn #A"
                clearable
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="msisdn"
                label="Enter msisdn #B"
                clearable
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="tarikh"
                label="YYYY-MM-DD"
                clearable
              />
            </v-flex>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="refreshList()"
              >
                Search
              </v-btn>
            </v-card-actions>
            <v-dialog
              v-model="deleteTopupDialog"
              max-width="800px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Delete Topup Record?</span>
                </v-card-title>
                <v-alert
                  :value="true"
                  type="warning"
                >
                  This action is irreversible! Are you sure you want to delete
                  this record?
                </v-alert>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    flat
                    @click="closeAllDialogs(false)"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="red darken-1"
                    flat
                    @click="deleteTopupRecord"
                  >
                    Delete Topup
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="adjustment"
        class="elevation-1"
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.tarikh }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-center">
            {{ props.item.nohp }}
          </td>
          <td class="text-xs-center">
            {{ props.item.amaun }}
          </td>
          <td class="text-xs-right">
            {{ props.item.kaedah }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              color="red lighten-2"
              @click="deleteTopup(props.item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Refund to Dealer
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-alert
              :value="true"
              :type="executeAlertType"
              v-html="executeAlertMessage"
            />
            <v-form
              ref="refundForm"
              v-model="validAmount"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="refundAmount.amount"
                      label="Amount"
                      :rules="[(val) => !!val || 'Amount required!']"
                      validate-on-blur
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-alert
            v-model="alert"
            :type="executeAlertType"
          >
            {{ alertMessage }}
          </v-alert>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === "info" ? "Cancel" : "Close" }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              color="blue darken-1"
              flat
              @click="refundErecharge"
            >
              {{ formTitle }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Credit Adjustment'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    dialog: false,
    title: pageTitle,
    source: null,
    loading: false,
    deleteTopupDialog: false,
    refundDialog: false,
    dreg: null,
    msisdn: null,
    tarikh: null,
    adjustment: [],
    items: [],
    breadcrumbs: [
      {
        text: 'Technical Kits',
        disabled: false,
        to: '/technical/technical_kits',
      },
      {
        text: pageTitle,
        disabled: true,
      },
    ],
    headers: [
      {
        text: 'Date',
        align: 'left',
        sortable: true,
        value: 'tarikh',
      },
      {
        text: 'Dreg',
        align: 'left',
        sortable: true,
        value: 'dealer',
      },
      {
        text: 'Msisdn',
        align: 'center',
        sortable: true,
        value: 'nohp',
      },
      {
        text: 'Amount',
        align: 'center',
        sortable: true,
        value: 'amaun',
      },
      {
        text: 'Kaedah',
        align: 'right',
        sortable: true,
        value: 'kaedah',
      },
      {
        text: 'Action',
        align: 'center',
        sortable: true,
        value: 'action',
      },
    ],
    editedIndex: -1,
    refundAmount: {
      id: null,
      dealer: null,
      amount: null,
    },
    defaultAmount: {
      id: null,
      dealer: null,
      amount: null,
    },
    formTitle: 'Refund Erecharge',
    validAmount: true,
    alertMessage: null,
    alert: false,
    executeDialog: false,
    executeAlertType: 'info',
    executeAlertMessage: null,
    snackbarText: null,
    snackbar: false,
    snackbarError: false,
  }),
  watch: {
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage =
          '<div>Please ensure that you have  <strong>deleted topup record </strong>from user before refund to dealer! .</div>'
      }
    },
    dreg: function (val) {
      this.getAdjustmentInfo(val)
    },
    msisdn: function (val) {
      this.getAdjustmentInfo(val)
    },
    tarikh: function (val) {
      this.getAdjustmentInfo(val)
    },
    deleteTopupDialog (val) {
      val || this.closeAllDialogs(false)
    },
    refundDialog (val) {
      if (val) {
        this.$refs.refundForm.resetValidation()
      } else {
        this.closeAllDialogs(false)
      }
    },
  },
  methods: {
    deleteItem (item) {
      const index = this.msisdns.indexOf(item)
      this.msisdns.splice(index, 1)
    },
    getAdjustmentInfo () {
      const params = createGetParams({
        dreg: this.dreg,
        msisdn: this.msisdn,
        tarikh: this.tarikh,
      })
      this.loading = true
      this.$rest
        .get('getAdjustmentInfo.php', params)
        .then((response) => {
          this.adjustment = response.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    deleteTopup (id) {
      this.editedIndex = this.adjustment.indexOf(id)
      this.refundAmount = Object.assign({}, id)
      this.deleteTopupDialog = true
    },
    async deleteTopupRecord () {
      try {
        const params = createPostParams({
          action: 'deleteTopup',
          payload: {
            id: this.refundAmount.id,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'You have successfully deleted a topup record'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    refreshList () {
      this.getAdjustmentInfo().then((data) => {
        this.items = data.items
      })
    },
    closeAllDialogs (save) {
      this.dialog = false
      this.deleteTopupDialog = false
      this.alert = false
      setTimeout(() => {
        this.refundAmount = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        save && this.getAdjustmentInfo()
      }, 300)
    },

    async refundErecharge () {
      const params = createPostParams({
        action: 'refundErecharge',
        payload: {
          dealer: this.dreg,
          amount: this.refundAmount.amount,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage =
            'Your request to refund erecharge to msisdn #A was successful.'
          this.closeAllDialogs(true)
        })
        location.reload()
      } catch (error) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = error.message + ': ' + error.response.data
        })
      } finally {
        this.loading = false
      }
    },
    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
